/* eslint-disable jsx-a11y/img-redundant-alt */

/* eslint-disable react/jsx-props-no-spreading */
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { useRef } from 'react';

import Blocks from '../../components/blocks/Blocks';
import FolderIcon from './FolderIcon';

import styles from './styles/recours-page.module.css';

const propTypes = {
    className: PropTypes.string,
};

const defaultProps = {
    className: null,
};

function RecoursPage({ blocks, className }) {
    const containerRef = useRef();
    return (
        <div
            className={classNames([
                styles.container,
                {
                    [className]: className !== null,
                },
            ])}
            ref={containerRef}
        >
            <div className={styles.header}>
                <FolderIcon className={styles.logo} />
                <h1 className={styles.hidden}>Le Répertoire des Recours Collectifs</h1>
            </div>
            <Blocks className={styles.blocks} blocks={blocks} />
        </div>
    );
}

RecoursPage.propTypes = propTypes;
RecoursPage.defaultProps = defaultProps;

// eslint-disable-next-line react/jsx-props-no-spreading
export default RecoursPage;
