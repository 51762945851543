/* eslint-disable react/require-default-props */

/* eslint-disable react/jsx-props-no-spreading */

/* eslint-disable no-unused-vars */
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

const propTypes = {
    color1: PropTypes.string,
    color2: PropTypes.string,
    color3: PropTypes.string,
    color4: PropTypes.string,
    color5: PropTypes.string,
    className: PropTypes.string,
};

function FolderIcon({
    color1 = '#c0daff',
    color2 = '#5e79ff',
    color3 = '#fff',
    color4 = '#f04e3e',
    color5 = '#45a',
    color6 = '#ffccde',
    className,
}) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="294.5"
            height="196.9"
            viewBox="0 0 294.5 196.9"
            fill="none"
            className={className}
        >
            <path
                fill={color5}
                d="M265.5,24.8H124.5c-6.5,0-12.7-2.8-16.9-7.8l-8.8-9.3c-4.3-4.9-10.4-7.8-16.9-7.8H11.2C5,0,0,5.1,0,11.4v182.2C0,194.9,1,195.9,2.2,195.9h272.2c1.2,0,2.2-1,2.2-2.3V36.2c0-6.3-5-11.4-11.2-11.4Z"
            />
            <g>
                <g>
                    <path
                        fill={color1}
                        d="M10.2,10.5c1-1.1,2.3-1.6,3.8-1.6s2.8.5,3.8,1.6c1,1.1,1.6,2.4,1.6,3.9s-.4,2.5-1.2,3.5l1.4,1.4-.8.8-1.4-1.4c-1,.8-2.1,1.2-3.4,1.2s-2.8-.5-3.8-1.6c-1-1.1-1.5-2.4-1.5-3.9s.5-2.8,1.5-3.9ZM15.3,16.6l.8-.8,1.3,1.3c.6-.8.9-1.7.9-2.8s-.4-2.3-1.2-3.1c-.8-.9-1.8-1.3-3-1.3s-2.2.4-3,1.3c-.8.8-1.2,1.9-1.2,3.1s.4,2.3,1.2,3.2c.8.8,1.8,1.3,3,1.3s1.8-.3,2.6-.9l-1.3-1.3Z"
                    />
                    <path
                        fill={color1}
                        d="M28.4,9h1.2v6.5c0,1.3-.4,2.3-1.1,3.1-.7.8-1.7,1.2-3,1.2s-2.2-.4-3-1.2c-.7-.8-1.1-1.9-1.1-3.1v-6.5h1.2v6.5c0,.9.2,1.7.7,2.3.5.6,1.2.9,2.1.9s1.6-.3,2.1-.9c.5-.6.8-1.4.8-2.3v-6.5Z"
                    />
                    <path
                        fill={color1}
                        d="M36.3,9l4.1,10.7h-1.2l-1-2.4h-5.2l-.9,2.4h-1.3l4.2-10.7h1.3ZM35.6,10.5l-2.1,5.6h4.3l-2.2-5.6Z"
                    />
                    <path fill={color1} d="M40.5,9h7.9v1.1h-3.4v9.6h-1.2v-9.6h-3.3v-1.1Z" />
                    <path
                        fill={color1}
                        d="M57.2,19.7l-2.8-3.9h-2.5v3.9h-1.2v-10.7h3.7c1.2,0,2.1.3,2.8.9.7.6,1.1,1.4,1.1,2.4,0,1.6-1,2.8-2.6,3.2l2.8,4.1h-1.4ZM51.8,10.1v4.6h2.7c1.6,0,2.6-.8,2.6-2.3s-1-2.3-2.6-2.3h-2.7Z"
                    />
                    <path
                        fill={color1}
                        d="M61.2,19.7v-10.7h7v1.1h-5.8v3.6h5.7v1.1h-5.7v3.8h5.8v1.1h-7Z"
                    />
                    <path
                        fill={color1}
                        d="M82.3,10.4c.7,1,1.1,2.4,1.1,4.1s-.3,3-1,4c-.7,1-1.7,1.4-3,1.4s-2.2-.5-2.9-1.4l.8-.9c.5.8,1.2,1.1,2.1,1.1s1.7-.4,2.2-1.2c.5-.8.7-1.9.7-3.2-.6,1.1-1.7,1.6-2.8,1.6s-1.7-.3-2.4-1c-.7-.7-1-1.5-1-2.6s.3-1.9,1-2.6c.7-.7,1.5-1,2.4-1s2.1.5,2.8,1.5ZM81.2,14.1c.4-.4.7-1,.7-1.7s-.2-1.3-.7-1.8c-.5-.5-1-.7-1.7-.7s-1.2.2-1.6.6c-.4.4-.7,1-.7,1.8s.2,1.3.7,1.8c.5.4,1,.7,1.6.7s1.2-.2,1.6-.7Z"
                    />
                    <path
                        fill={color1}
                        d="M89.2,14c-.9,0-1.8.5-2.1,1.2h-1l.3-6.2h5.7v1.1h-4.6l-.2,3.6c.4-.5,1.2-.8,2.2-.8s1.7.3,2.4.9,1,1.5,1,2.5-.3,1.8-1,2.5-1.6,1-2.7,1-2.4-.5-3.3-1.6l.8-.8c.7.9,1.6,1.4,2.6,1.4s1.3-.2,1.8-.7c.5-.4.7-1,.7-1.7,0-1.5-1-2.4-2.4-2.4Z"
                    />
                </g>
                <polygon fill={color1} points="71.3 21.3 70.5 20.4 73.6 17.4 74.4 18.2 71.3 21.3" />
            </g>
            <path
                fill={color6}
                d="M279.6,28.9H22.1c-3.1,0-5.9,2.5-6.2,5.7L1.9,176.8c-.3,3.1,2,5.7,5.1,5.7h257.5c3.1,0,5.9-2.5,6.2-5.7l14-142.3c.3-3.1-2-5.7-5.1-5.7Z"
            />
            <path
                fill={color3}
                d="M274.8,31.4H17.3c-3.1,0-5.8,2.5-6.1,5.7L1.6,179.3c-.2,3.1,2.2,5.7,5.3,5.7h257.5c3.1,0,5.8-2.5,6.1-5.7l9.6-142.3c.2-3.1-2.2-5.7-5.3-5.7Z"
            />
            <path
                fill={color2}
                d="M30.6,33.1h253.7c6.4,0,10.9,5.2,10.2,11.5l-17.7,148.7c-.2,1.9-2,3.5-3.9,3.5H3.1c-1.9,0-3.3-1.5-3-3.5L17.7,44.7c.8-6.4,6.5-11.5,12.9-11.5Z"
            />
            <g>
                <path
                    fill={color3}
                    d="M49.1,74.8l-5.4-10.1h-7l-1.4,10.1h-3l3.9-27.4h9.5c2.7,0,4.9.7,6.5,2.2s2.4,3.3,2.4,5.6c0,4.8-3,8.2-7.9,9.2l5.6,10.4h-3.2ZM38.6,50.2l-1.6,11.7h7c4.5,0,7.5-2.3,7.5-6.7s-2.2-5-5.9-5h-7Z"
                />
                <path
                    fill={color3}
                    d="M60.4,66.9c0,3.6,2.1,5.6,5.6,5.6s4.2-.9,5.9-2.8l1.8,2c-2.2,2.3-4.9,3.5-8,3.5-5,0-8.2-3.4-8.2-8.4s1-5.9,2.9-8.2,4.4-3.4,7.4-3.4,4.3.8,5.5,2.4c1.2,1.6,1.8,3.5,1.8,5.8s-.1,1.9-.3,2.8h-14.3c0,.2,0,.4,0,.8ZM60.9,63.5h11.3c.1-3.5-1.6-5.6-4.8-5.6s-5.6,2.3-6.5,5.6ZM69.8,47.3h3.8l-5.2,5.1h-2.9l4.3-5.1Z"
                />
                <path
                    fill={color3}
                    d="M96,72c-2,2.2-4.5,3.3-7.5,3.3s-4.6-1.1-6-3.2l-1.4,10.3h-2.9l3.8-26.8h2.8l-.3,2.4c1.9-1.9,4.1-2.9,6.8-2.9s4.2.9,5.6,2.5,2.2,3.7,2.2,6.1-1,6-3,8.2ZM90.7,57.9c-2.2,0-3.9.8-5.3,2.5s-2.1,3.6-2.1,6c0,3.6,2,6.2,5.4,6.2s3.9-.8,5.3-2.5c1.4-1.7,2.1-3.7,2.1-6,0-3.6-2-6.1-5.3-6.1Z"
                />
                <path
                    fill={color3}
                    d="M121.1,66.1h-14.3c0,.2,0,.4,0,.8,0,3.6,2.1,5.6,5.6,5.6s4.2-.9,5.9-2.8l1.8,2c-2.2,2.3-4.9,3.5-8,3.5-5,0-8.2-3.4-8.2-8.4s1-5.9,2.9-8.2,4.4-3.4,7.4-3.4,4.3.8,5.5,2.4c1.2,1.6,1.8,3.5,1.8,5.8s-.1,1.9-.2,2.4v.4ZM107.3,63.5h11.3c.1-3.5-1.6-5.6-4.8-5.6s-5.6,2.3-6.5,5.6Z"
                />
                <path
                    fill={color3}
                    d="M137.9,58.1c-.2,0-.4,0-.7,0-4.5,0-6.8,2.3-7.6,8.5l-1.1,8.2h-2.9l2.7-19.2h2.8l-.4,2.5c1.9-2.1,3.8-2.8,6.7-2.8s.6,0,.9,0l-.4,2.8Z"
                />
                <path
                    fill={color3}
                    d="M142.2,70.3c0-.5,0-1,.1-1.5l1.4-10.5h-3.1l.4-2.7h3.1l.7-5.4h2.9l-.7,5.4h4.5l-.4,2.7h-4.5l-1.5,10.4c0,.5-.1.9-.1,1.3,0,1.5.7,2.2,2.6,2.2h1.5s-.4,2.6-.4,2.6c-.5,0-1.1,0-1.7,0-3.2,0-4.9-1.5-4.9-4.6Z"
                />
                <path
                    fill={color3}
                    d="M157.5,58.3c2.1-2.2,4.7-3.2,7.7-3.2s4.7.9,6.3,2.6c1.6,1.7,2.4,3.8,2.4,6.4s-1.1,5.8-3.1,7.9c-2.1,2.1-4.7,3.2-7.7,3.2s-4.7-.9-6.3-2.6c-1.6-1.7-2.3-3.8-2.3-6.4s1-5.8,3.1-7.9ZM159.1,70.7c1.1,1.2,2.5,1.8,4.3,1.8s4-.8,5.4-2.4c1.4-1.6,2.2-3.6,2.2-5.9s-.5-3.3-1.6-4.5c-1.1-1.2-2.5-1.8-4.3-1.8s-4,.8-5.4,2.4c-1.4,1.6-2.2,3.6-2.2,5.9s.5,3.3,1.6,4.5Z"
                />
                <path
                    fill={color3}
                    d="M178.9,74.8l2.7-19.2h2.9l-2.7,19.2h-2.9ZM183.9,51.3c-1.8.2-2.9-2.4-1.5-3.6,1.3-1.3,3.6-.4,3.6,1.5s-.9,2.1-2.2,2.1Z"
                />
                <path
                    fill={color3}
                    d="M201.1,58.1c-.2,0-.4,0-.7,0-4.5,0-6.8,2.3-7.6,8.5l-1.1,8.2h-2.9l2.7-19.2h2.8l-.4,2.5c1.9-2.1,3.8-2.8,6.7-2.8s.6,0,.9,0l-.4,2.8Z"
                />
                <path
                    fill={color3}
                    d="M220.3,66.1h-14.3c0,.2,0,.4,0,.8,0,3.6,2.1,5.6,5.6,5.6s4.2-.9,5.9-2.8l1.8,2c-2.2,2.3-4.9,3.5-8,3.5-5,0-8.2-3.4-8.2-8.4s1-5.9,2.9-8.2,4.4-3.4,7.4-3.4,4.3.8,5.5,2.4c1.2,1.6,1.8,3.5,1.8,5.8s-.1,1.9-.2,2.4v.4ZM206.5,63.5h11.3c.1-3.5-1.6-5.6-4.8-5.6s-5.6,2.3-6.5,5.6Z"
                />
                <path
                    fill={color3}
                    d="M30.3,92.9c2-2.2,4.5-3.3,7.5-3.3s4.6,1.1,6,3.2l1.6-11h2.9l-3.9,27.4h-2.8l.3-2.4c-1.9,1.9-4.1,2.9-6.8,2.9s-4.2-.8-5.6-2.5-2.2-3.7-2.2-6.1,1-6,3-8.2ZM35.6,106.9c2.2,0,3.9-.8,5.3-2.5s2.1-3.6,2.1-6c0-3.6-2-6.2-5.4-6.2s-3.9.8-5.3,2.5c-1.4,1.6-2.1,3.6-2.1,6,0,3.6,2,6.1,5.3,6.1Z"
                />
                <path
                    fill={color3}
                    d="M68.6,100.5h-14.3c0,.2,0,.4,0,.8,0,3.6,2.1,5.6,5.6,5.6s4.2-.9,5.9-2.8l1.8,2c-2.2,2.3-4.9,3.5-8,3.5-5,0-8.2-3.4-8.2-8.4s1-5.9,2.9-8.2,4.4-3.4,7.4-3.4,4.3.8,5.5,2.4c1.2,1.6,1.8,3.5,1.8,5.8s-.1,1.9-.2,2.4v.4ZM54.8,97.9h11.3c.1-3.5-1.6-5.6-4.8-5.6s-5.6,2.3-6.5,5.6Z"
                />
                <path
                    fill={color3}
                    d="M79.3,100.6c-3.1-.7-4.9-2.6-4.9-5.2s.7-3.2,2-4.2c1.4-1.1,3.1-1.6,5-1.6,2.8,0,5.1,1.1,6.8,3.1l-2.1,2c-1.4-1.6-3-2.4-4.8-2.4s-3.9,1.3-3.9,3,.9,2.2,2.7,2.5l2.7.7c3.2.8,4.7,2.5,4.7,5.1s-3.2,6-7.7,6-3.1-.4-4.4-1.1c-1.4-.7-2.3-1.6-3-2.6l2.1-1.9c1.2,1.8,3.4,2.9,5.6,2.9s4.5-1.1,4.5-3.1-.9-2.2-2.9-2.6l-2.4-.6Z"
                />
                <path
                    fill={color3}
                    d="M117.2,92.6c-.2,0-.4,0-.7,0-4.5,0-6.8,2.3-7.6,8.5l-1.1,8.2h-2.9l2.7-19.2h2.8l-.4,2.5c1.9-2.1,3.8-2.8,6.7-2.8s.6,0,.9,0l-.4,2.8Z"
                />
                <path
                    fill={color3}
                    d="M136.3,100.5h-14.3c0,.2,0,.4,0,.8,0,3.6,2.1,5.6,5.6,5.6s4.2-.9,5.9-2.8l1.8,2c-2.2,2.3-4.9,3.5-8,3.5-5,0-8.2-3.4-8.2-8.4s1-5.9,2.9-8.2,4.4-3.4,7.4-3.4,4.3.8,5.5,2.4c1.2,1.6,1.8,3.5,1.8,5.8s-.1,1.9-.2,2.4v.4ZM122.5,97.9h11.3c.1-3.5-1.6-5.6-4.8-5.6s-5.6,2.3-6.5,5.6Z"
                />
                <path
                    fill={color3}
                    d="M157.3,106.8c-1.7,1.9-4.1,2.8-7.3,2.8s-4.5-.8-6.1-2.4c-1.6-1.6-2.4-3.7-2.4-6.3s1.1-5.7,3.2-7.9c2.2-2.2,4.8-3.4,7.8-3.4s5.4,1.1,6.9,3.4l-2.2,1.9c-1.2-1.7-2.8-2.6-4.9-2.6s-4.1.8-5.6,2.5c-1.5,1.6-2.3,3.6-2.3,5.9,0,3.7,2.2,6.2,5.9,6.2s4.1-.9,5.7-2.6l1.2,2.5Z"
                />
                <path
                    fill={color3}
                    d="M165.6,92.8c2.1-2.2,4.7-3.2,7.7-3.2s4.7.9,6.3,2.6c1.6,1.7,2.4,3.8,2.4,6.4s-1.1,5.8-3.1,7.9c-2.1,2.1-4.7,3.2-7.7,3.2s-4.7-.9-6.3-2.6c-1.6-1.7-2.3-3.8-2.3-6.4s1-5.8,3.1-7.9ZM167.1,105.2c1.1,1.2,2.5,1.8,4.3,1.8s4-.8,5.4-2.4c1.4-1.6,2.2-3.6,2.2-5.9s-.5-3.3-1.6-4.5c-1.1-1.2-2.5-1.8-4.3-1.8s-4,.8-5.4,2.4c-1.4,1.6-2.2,3.6-2.2,5.9s.5,3.3,1.6,4.5Z"
                />
                <path
                    fill={color3}
                    d="M199,109.2l.3-2.2c-1.6,1.8-3.7,2.7-6.2,2.7-4.1,0-6.3-2.5-6.3-6.7s0-1.5.2-2.2l1.5-10.7h2.9l-1.6,11.3c0,.5-.1,1-.1,1.4,0,2.8,1.3,4.2,4,4.2s5.9-2.7,6.6-7.4l1.3-9.5h2.9l-2.7,19.2h-2.8Z"
                />
                <path
                    fill={color3}
                    d="M221.2,92.6c-.2,0-.4,0-.7,0-4.5,0-6.8,2.3-7.6,8.5l-1.1,8.2h-2.9l2.7-19.2h2.8l-.4,2.5c1.9-2.1,3.8-2.8,6.7-2.8s.6,0,.9,0l-.4,2.8Z"
                />
                <path
                    fill={color3}
                    d="M229.1,100.6c-3.1-.7-4.9-2.6-4.9-5.2s.7-3.2,2-4.2c1.4-1.1,3.1-1.6,5-1.6,2.8,0,5.1,1.1,6.8,3.1l-2.1,2c-1.4-1.6-3-2.4-4.8-2.4s-3.9,1.3-3.9,3,.9,2.2,2.7,2.5l2.7.7c3.2.8,4.7,2.5,4.7,5.1s-3.2,6-7.7,6-3.1-.4-4.4-1.1c-1.4-.7-2.3-1.6-3-2.6l2.1-1.9c1.2,1.8,3.4,2.9,5.6,2.9s4.5-1.1,4.5-3.1-.9-2.2-2.9-2.6l-2.4-.6Z"
                />
                <path
                    fill={color3}
                    d="M37.8,141.3c-1.7,1.9-4.1,2.8-7.3,2.8s-4.5-.8-6.1-2.4c-1.6-1.6-2.4-3.7-2.4-6.3s1.1-5.7,3.2-7.9c2.2-2.2,4.8-3.4,7.8-3.4s5.4,1.1,6.9,3.4l-2.2,1.9c-1.2-1.7-2.8-2.6-4.9-2.6s-4.1.8-5.6,2.5c-1.5,1.6-2.3,3.6-2.3,5.9,0,3.7,2.2,6.2,5.9,6.2s4.1-.9,5.7-2.6l1.2,2.5Z"
                />
                <path
                    fill={color3}
                    d="M46.2,127.2c2.1-2.2,4.7-3.2,7.7-3.2s4.7.9,6.3,2.6c1.6,1.7,2.4,3.8,2.4,6.4s-1.1,5.8-3.1,7.9c-2.1,2.1-4.7,3.2-7.7,3.2s-4.7-.9-6.3-2.6c-1.6-1.7-2.3-3.8-2.3-6.4s1-5.8,3.1-7.9ZM47.7,139.6c1.1,1.2,2.5,1.8,4.3,1.8s4-.8,5.4-2.4,2.2-3.6,2.2-5.9-.5-3.3-1.6-4.5c-1.1-1.2-2.5-1.8-4.3-1.8s-4,.8-5.4,2.4c-1.4,1.6-2.2,3.6-2.2,5.9s.5,3.3,1.6,4.5Z"
                />
                <path fill={color3} d="M66.9,143.6l3.9-27.4h2.9l-3.9,27.4h-2.9Z" />
                <path fill={color3} d="M76.9,143.6l3.9-27.4h2.9l-3.9,27.4h-2.9Z" />
                <path
                    fill={color3}
                    d="M104,135h-14.3c0,.2,0,.4,0,.8,0,3.6,2.1,5.6,5.6,5.6s4.2-.9,5.9-2.8l1.8,2c-2.2,2.3-4.9,3.5-8,3.5-5,0-8.2-3.4-8.2-8.4s1-5.9,2.9-8.2c1.9-2.3,4.4-3.4,7.4-3.4s4.3.8,5.5,2.4c1.2,1.6,1.8,3.5,1.8,5.8s-.1,1.9-.2,2.4v.4ZM90.2,132.4h11.3c.1-3.5-1.6-5.6-4.8-5.6s-5.6,2.3-6.5,5.6Z"
                />
                <path
                    fill={color3}
                    d="M125,141.3c-1.7,1.9-4.1,2.8-7.3,2.8s-4.5-.8-6.1-2.4c-1.6-1.6-2.4-3.7-2.4-6.3s1.1-5.7,3.2-7.9c2.2-2.2,4.8-3.4,7.8-3.4s5.4,1.1,6.9,3.4l-2.2,1.9c-1.2-1.7-2.8-2.6-4.9-2.6s-4.1.8-5.6,2.5c-1.5,1.6-2.3,3.6-2.3,5.9,0,3.7,2.2,6.2,5.9,6.2s4.1-.9,5.7-2.6l1.2,2.5Z"
                />
                <path
                    fill={color3}
                    d="M132.4,139.1c0-.5,0-1,.1-1.5l1.4-10.5h-3.1l.4-2.7h3.1l.7-5.4h2.9l-.7,5.4h4.5l-.4,2.7h-4.5l-1.5,10.4c0,.5-.1.9-.1,1.3,0,1.5.7,2.2,2.6,2.2h1.5s-.4,2.6-.4,2.6c-.5,0-1.1,0-1.7,0-3.2,0-4.9-1.5-4.9-4.6Z"
                />
                <path
                    fill={color3}
                    d="M145.1,143.6l2.7-19.2h2.9l-2.7,19.2h-2.9ZM150.1,120.1c-1.8.2-2.9-2.4-1.5-3.6,1.3-1.3,3.6-.4,3.6,1.5s-.9,2.1-2.2,2.1Z"
                />
                <path
                    fill={color3}
                    d="M167.2,116.3l-.4,2.6h-1c-2.9,0-4,1.3-4.5,4.1l-.2,1.4h4.5l-.4,2.7h-4.5l-2.3,16.5h-2.9l2.3-16.5h-3.1l.4-2.7h3.1l.2-1.4c.6-4.7,3.2-6.8,7.7-6.8h1.1Z"
                />
                <path
                    fill={color3}
                    d="M174.2,135.1c-3.1-.7-4.9-2.6-4.9-5.2s.7-3.2,2-4.2c1.4-1.1,3.1-1.6,5-1.6,2.8,0,5.1,1.1,6.8,3.1l-2.1,2c-1.4-1.6-3-2.4-4.8-2.4s-3.9,1.3-3.9,3,.9,2.2,2.7,2.5l2.7.7c3.2.8,4.7,2.5,4.7,5.1s-3.2,6-7.7,6-3.1-.4-4.4-1.1c-1.4-.7-2.3-1.6-3-2.6l2.1-1.9c1.2,1.8,3.4,2.9,5.6,2.9s4.5-1.1,4.5-3.1-.9-2.2-2.9-2.6l-2.4-.6Z"
                />
            </g>
            <g>
                <circle fill={color4} cx="231.7" cy="24.5" r="20" />
                <path
                    fill={color3}
                    d="M230.1,38.1v-3.2c-2.9-.4-5.4-1.9-7-3.9l3-3.1c1,1.4,2.3,2.3,3.9,2.7v-4.6c-4-.7-6.4-2.8-6.4-6.2s.6-3.1,1.8-4.2c1.2-1.1,2.8-1.8,4.6-2v-3.1h3.3v3.2c2.4.4,4.4,1.5,6,3.1l-3,3.1c-.9-.9-1.9-1.6-3-2v4.2c4.5.8,6.7,3,6.7,6.4s-2.6,5.8-6.7,6.3v3.1h-3.3ZM230.1,21.7v-4c-1.3.3-2,1-2,2s.7,1.6,2,2ZM233.4,26.7v4.2c1.4-.3,2.3-1.1,2.3-2.1s-.8-1.7-2.3-2Z"
                />
            </g>
        </svg>
    );
}

FolderIcon.propTypes = propTypes;

export default FolderIcon;
